import Marzipano from "marzipano";

export default class LogoController {

    constructor({
        container = document.body,
    } = {}) {
        this.buildHtml();
        container.appendChild(this.html);

        this.viewer = new Marzipano.Viewer(this.html);

        // Create geometry.
        this.geometry = new Marzipano.EquirectGeometry([{ width: 4000 }]);

        // Create view.
        const limiter = Marzipano.RectilinearView.limit.traditional(1024, 100 * Math.PI / 180);
        // Dont know how to zoom with marzipano, so just set a big fov... THANKS MARZIPANO
        this.view = new Marzipano.RectilinearView({ yaw: 0, fov: 10 }, limiter);
        this.scene = this.viewer.createEmptyScene({ view: this.view });

        this.autorotate = Marzipano.autorotate({
            yawSpeed: 0.1,         // Yaw rotation speed
            targetPitch: 0,        // Pitch value to converge to
            targetFov: Math.PI / 2,   // Fov value to converge to
        });

        // Autorotate will start after 3s of idle time
        this.viewer.setIdleMovement(3000, this.autorotate);

        this.autoRotate(false);
    }

    buildHtml() {
        this.html = document.createElement("div");
        this.html.className = "marzipano-viewer";
    }

    openFromUrl(url) {
        const source = Marzipano.ImageUrlSource.fromString(url);
        if (this.scene.layer()) {
            this.scene.destroyLayer(this.scene.layer());
        }
        this.scene.createLayer({
            source,
            geometry: this.geometry,
            pinFirstLevel: true,
        });

        // Display scene.
        this.scene.switchTo();
    }

    autoRotate(turn) {
        if (turn) {
            this.viewer.startMovement(this.autorotate);
        } else {
            this.viewer.stopMovement();
        }
    }

}
