import "@babel/polyfill";
import obsidian from "@obsidianjs/obsidian";

import iframeApi from "@obsidianjs/iframe-api";

import marzipanoModule from "./modules/marzipano";

import "./style/index.less";

const app = obsidian("starter-app");
app.use(iframeApi);
app.use(marzipanoModule);

app.start();
